/**
 * 政策条 列表&收藏融合使用
 */

import schttp from 'public/src/services/schttp'
import { customMarkPoint } from 'public/src/services/mark/index.js'

let policyBannerAbortionInstance = null
let defaultAddressAbortionInstance = null
let policyBannerLanguageAbortionInstance = null
const URL_POLICY_BANNER = '/ccc/nav/component'
const URL_POLICY_BANNER_LANGUAGE = '/api/home/component/freeshippingLanguage'
const URL_DEFAULT_ADDRESS = '/api/user/address/default/get'

export const getBffPolicyBanner = async (policyBannerParams = {}) => {
  policyBannerAbortionInstance && policyBannerAbortionInstance?.abort()
  defaultAddressAbortionInstance && defaultAddressAbortionInstance?.abort()
  policyBannerLanguageAbortionInstance && policyBannerLanguageAbortionInstance?.abort()

  if (SchttpAbortCon) {
    policyBannerAbortionInstance = new SchttpAbortCon()
    defaultAddressAbortionInstance = new SchttpAbortCon()
    policyBannerLanguageAbortionInstance = new SchttpAbortCon()
  }
  
  const markApiEnd = customMarkPoint({ eventName: URL_POLICY_BANNER })
  const countryData = await schttp({
    url: URL_DEFAULT_ADDRESS,
    signal: defaultAddressAbortionInstance.signal,
  })
  
  const params = {
    cateId: '',
    cateType: '',
    pageScene: 'list',
    cccPageType: '',
    blackbox: '',
    pageFrom: 'list',
    country_id: countryData?.info?.countryId || '',
    ...policyBannerParams
  }
  
  const [results, language] = await Promise.all([
    // 中间层政策条数据
    schttp({
      url: URL_POLICY_BANNER,
      params,
      signal: policyBannerAbortionInstance.signal,
      isPreLoad: true,
      useBffApi: true
    }),
    // node首页政策条多语言
    schttp({
      url: URL_POLICY_BANNER_LANGUAGE,
      signal: policyBannerAbortionInstance.signal,
      isPreLoad: true
    })
  ])
  markApiEnd()
  
  return {
    language,
    isBff: true,
    isXhrBff: true,
    content: results?.info || {},
    hasFreeshippingComp: results?.info?.content?.length > 0
  }
}
