/**
 * ==================  职责：预请求，缓存数据当js内存中。 ==================
 * 1. 相同参数的请求，只发一次。 如果同时发起多次相同参数的请求，只有第一次会发起请求，后续的请求会等待第一次请求的结果。
 * 2. 如果返回的数据为空或者异常，返回数据，但是不缓存数据。
 * 3. 缓存在内存的时间默认为 1 分钟 (60 * 1000 毫秒), 可以通过配置 cacheTime 来修改缓存时间。
 * 4. 通过配置 notCacheKeys 可以指定不参与缓存的参数。
 * 5. 通过配置 maxCacheNumber 可以指定最大缓存数量。
 * 6. 通过 clear 方法可以清空缓存。
 * 7. 使用最近最少使用算法（LRU）来管理缓存。
*/

const defaultConfig =  {
  cacheTime: 1 * 60 * 1000, // 默认缓存时间为 1 分钟, 单位为毫秒
  notCacheKeys: [],         // 默认不参与缓存的参数, Array[String]
  maxCacheNumber: 10,       // 默认缓存数量为 10
}

class ApiCache {
  constructor(config) {
    this.pendingRequests = new Map()
    this.config = Object.assign({}, defaultConfig, config)
    this.cacheControl = new LRUCache(this.config.maxCacheNumber, this.config.cacheTime)
  }

  /**
   * 请求数据
   * @return {Promise} 返回请求的结果
  */
  request(params, options = {}) {
    const { cacheKey, cacheData } = this._getCacheData(params)
    if (cacheData) {
      return Promise.resolve(cacheData)
    }

    if (this.pendingRequests.has(cacheKey)) {
      return this.pendingRequests.get(cacheKey)
    }
    
    const promise = new Promise((resolve, reject) => {
      this.config
        .request(params, options)
        .then(result => {
          if (result) { 
            // 有结果才缓存
            this.cacheControl.put(cacheKey, result)
          }
          resolve(result)
        })
        .catch(reject)
        .finally(() => {
          this.pendingRequests.delete(cacheKey)
        })
    })
    this.pendingRequests.set(cacheKey, promise)
    return promise
  }

  getCacheData(params) {
    return this._getCacheData(params).cacheData
  }

  _getCacheData(data) {
    const cacheKey = serialize(data, this.config.notCacheKeys)
    const cacheData = this.cacheControl.get(cacheKey)
    return { cacheKey, cacheData: cacheData === -1 ? null : cacheData }    
  }

  clear() {
    this.cacheControl.clear()
  }
}

class LRUCache {
  constructor(capacity, cacheTime) {
    this.capacity = Math.max(capacity, 0) // 防止传入负数
    this.cacheTime = Math.max(cacheTime, 0) // 防止传入负数
    this.cache = new Map()
  }

  get(key) {
    if (!this.cache.has(key)) return -1
    const data = this.cache.get(key)
    this.cache.delete(key)
    if (data.expireTime <= Date.now()) {
      return -1
    }
    this.cache.set(key, data)
    return data.value
  }

  put(key, value) {
    if (this.cache.has(key)) {
      this.cache.delete(key)
    } 
    if (this.cache.size >= this.capacity) {
      this.cache.delete(this.cache.keys().next().value)
    }
    this.cache.set(key, {
      value,
      expireTime: Date.now() + this.cacheTime,
    })
  }

  delete(key) {
    this.cache.delete(key)
  }

  clear() {
    this.cache.clear()
  }
}

/**
 * 序列化对象
 * @param {Object} obj 需要序列化的对象
 * @param {Array} filterKeys 需要过滤的 key
 * @return {String} 返回序列化后的字符串
*/
function serialize(obj, filterKeys = []) {
  if (typeof obj !== 'object' || obj === null) {
    return JSON.stringify(obj)
  }
  if (Array.isArray(obj)) {
    const serializedArray = obj.map(item => serialize(item, filterKeys)).join(',')
    return `[${serializedArray}]`
  }
  const keys = Object.keys(obj).sort().filter(key => !filterKeys.includes(key))
  const serializedObj = keys
    .map(key => {
      const value = serialize(obj[key], filterKeys)
      return `"${key}":${value}`
    })
    .join(',')
  return `{${serializedObj}}`
}

export default ApiCache
